import Event from "./Event";
import dayjs, {Dayjs} from "dayjs";
import DeathDocumentType from "./DeathDocumentType";

export default class EventDocument {
    public id: string
    public path: string
    public event: Event
    public documentType: DeathDocumentType
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.path = data.path
        this.event = new Event(data.event)
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.documentType = new DeathDocumentType(data.proofDocument)
    }
}
