const BACKEND_ROUTES = {
    AUTH: {
        LOGIN: '/users/login',
        ME: '/users/me',
        STATISTICS: '/users/statistics',
        REGISTER: {
            MANAGERS: {
                CREATE: '/communities/registration/managers',
                GET_ONE: '/users/find/{id}',
                EMAIL_CHECKING: {
                    RESEND_CODE: '/users/{id}/send-verify-email-code',
                    CHECK_CODE: '/users/{id}/verify-email',
                },
            },
            COMMUNITIES: {
                CREATE: '/communities/registration',
                GET_ONE: '/communities/registration/communities',
                ACCEPT_CONDITIONS: '/communities/registration/accept-conditions',
            },
        },
        USERS: {
            GET_ONE: '/users/find/{id}',
            CREDENTIALS: '/users/{id}/credentials',
            PASSWORD: '/users/password',
            FORGOT_PASSWORD: {
                INIT: '/users/password/reset/link',
                CONFIRM: '/users/password/reset'
            }
        }
    },
    ADVERTS: {
        GET_ALL: '/adverts',
        GET_ONE: '/adverts/{id}',
        CREATE: '/adverts',
        UPDATE: '/adverts/{id}',
        DELETE: '/adverts/{id}',
    },
    ARTICLES: {
        GET_ALL: '/articles',
        GET_ONE: '/articles/{id}',
        CREATE: '/articles',
        UPDATE: '/articles/{id}',
        DELETE: '/articles/{id}',
    },
    COMMUNITY: {
        SELF: {
            GET_ALL: '/communities/paginated',
            DOWNLOAD_EXCEL: '/communities/excel',
            GET_ALL_SIMPLE: '/communities/simple/paginated',
            GET_ACTIVE: '/communities',
            UPDATE_COMMUNITY: '/communities',
            GET_ONE: '/communities/{id}',
            CREATE: '/communities',
            UPDATE: '/communities/{id}',
            OVERVIEW: '/communities/{id}/details',
            DELETE: '/communities/{id}',
            ACTIVATE: '/communities/{id}/activate',
            BLOCK: '/communities/{id}/block',
        },
        MANAGERS: {
            GET_ALL: '/managers/paginated',
            GET_ALL_BY_COMMUNITY: '/managers/community',
            GET_ONE: '/managers/{id}',
            GET_BY_COMMUNITY: '/managers/community/{id}/paginated',
            CREATE: '/managers',
            UPDATE: '/managers/{id}',
            DELETE: '/managers/{id}',
            BLOCK: '/managers/{id}/block',
        },
        MEMBERS: {
            GET_ALL: '/members/paginated',
            GET_DELETED: '/members/deleted',
            GET_RPN: '/members/all/paginated',
            GET_ONE: '/members/{id}',
            CREATE: '/members',
            RESPONSIBLES: '/members/responsibles',
            LOGS: '/system/settings/members/{id}/logs',
            GET_DOUBLONS: '/members/doublons',
            IMPORT_EXCEL: '/members/excel',
            DOWNLOAD_EXCEL: '/members/excel',
            UPDATE: '/members/{id}',
            DELETE: '/members/{id}/delete',
            ACTIVATE: '/members/admin/activate',
            ADMIN: {
                GET_ALL: '/members/admin/paginated',
                DOWNLOAD_EXCEL: '/members/admin/excel',
            }
        },
    },
    DEATHS: {
        GET_ALL: '/deaths/paginated',
        CREATE: '/deaths',
        UPDATE: '/deaths/{id}',
        GET_DOCUMENTS: '/deaths/documents',
        GET_PUBLIC_DOCUMENTS: '/deaths/documents/public',
        CREATE_OR_UPDATE_DOCUMENTS: '/deaths/documents',
        CREATE_OR_UPDATE_PUBLIC_DOCUMENTS: '/deaths/documents/public',
        GET_ONE: '/deaths/{id}',
        GENERATE_NOTICE: '/deaths/{id}/notice',
        GET_ONE_BY_MEMBER_ID: '/deaths/members/{id}',
        RESPONSE: '/deaths/{id}/response',
        ACCEPT: '/deaths/{id}/accept',
        ADD_DOCUMENTS: '/deaths/{id}/documents',
        CANCEL: '/deaths/{id}/cancel',
        APPROVE_DOCUMENTS: '/deaths/{id}/approve-documents',
        DELETE: '/deaths/{id}',
        ADMIN: {
            GET_ALL: '/deaths/admin/paginated',
        },
        COMMENTS: {
            FIND: '/comments/find',
            CREATE: '/comments',
            GET_ALL: '/comments/find/all',
            UPDATE: '/comments/{id}'
        },
        DOCUMENT_TYPES: {
            GET_ALL: '/deaths/documents/types/paginated',
            GET_ALL_NOT_PAGINATED: '/deaths/documents/types',
            CREATE: '/deaths/documents/types',
            UPDATE: '/deaths/documents/types/{id}',
        }
    },
    DOCUMENTS: {
        GET_ALL: '/documents/paginated',
        GET_ONE: '/documents/{id}',
        CREATE: '/documents',
        UPDATE: '/documents/{id}',
        DELETE: '/documents/{id}',
        ADMIN: {
            GET_ALL: '/documents/admin/paginated',
        },
    },
    FINANCES: {
        CONTRIBUTIONS: {
            GET_ALL: '/events/deaths/paginated',
            UPDATE_DEADLINE: '/events/{id}/deadline',
            DOWNLOAD_RECEIPT: '/events/{id}/receipt',
            PAY: '/events/{id}/pay',
            DOWNLOAD_EXCEL: '/events/excel',
            ADMIN: {
                GET_ALL: '/events/deaths/admin/paginated',
                STATS: '/events/statistics',
                GET_ALL_BY_EVENT: '/events/{id}/communities/paginated',
                DOWNLOAD_EXCEL: '/events/admin/excel',
            },
        },
        WALLETS: {
            GET_ONE: '/wallets',
            RECHARGE: '/wallets/credit',
            TRANSACTIONS: '/wallets/transactions/paginated',
        }
    },
    FOUNDERS: {
        GET_ALL: '/founders',
        GET_ONE: '/founders/{id}',
        CREATE: '/founders',
        UPDATE: '/founders/{id}',
        DELETE: '/founders/{id}',
    },
    PARTNERS: {
        GET_ALL: '/partners',
        CREATE: '/partners',
        DELETE: '/partners/{id}',
    },
    POLLS: {
        GET_ALL: '/polls/paginated',
        CREATE: '/polls',
        UPDATE: '/polls/{id}',
        DELETE: '/polls/{id}',
        GET_ONE: '/polls/{id}',
        FIND_DETAILS: '/polls/{id}/details',
        ACTIVATE: '/polls/{id}/activate',
        ANSWERS: '/polls/{id}/answers',
        PUBLISH_ANSWERS: '/polls/{id}/answers/publish',
        RESPONSE: '/polls/{id}/response',
        ITEMS: {
            GET_ALL: '/polls/{id}/items',
            CREATE: '/polls/items',
            UPDATE: '/polls/items/{id}',
            DELETE: '/polls/items/{id}',
        },
        OPTIONS: {
            GET_ALL: '/polls/items/{id}/options',
            CREATE: '/polls/items/options',
            UPDATE: '/polls/items/options/{id}',
            DELETE: '/polls/items/options/{id}',
        },
        ADMIN: {
            GET_ALL: '/polls/admin/paginated',
            GET_ALL_BY_EVENT: '/events/{id}/communities/paginated',
        },
    },
    TICKETS: {
        GET_ALL: '/tickets',
        GET_ONE: '/tickets/{id}',
        CREATE: '/tickets',
        UPDATE: '/tickets/{id}',
        TREAT: '/tickets/{id}/response',
        REAFFECT: '/tickets/{id}/reaffect',
        DELETE: '/tickets/{id}',
        TYPES : {
            GET_ALL: '/tickets/types',
            GET_ONE: '/tickets/types/{id}',
            CREATE: '/tickets/types',
            UPDATE: '/tickets/types/{id}', 
        }
    },
    EVENTS: {
        CREATE: '/events',
        UPDATE: '/events/{id}',
        DELETE: '/events/{id}',
        GET_ONE: '/events/{id}',
        CLOSE: '/events/{id}/close',
        GET_ALL: '/events/paginated',
        REMIND: '/events/{id}/reminders',
        ACTIVATE: '/events/{id}/activate',
        PRESENCES: '/events/{id}/presences',
        REACTIVATE: '/events/{id}/reactivate',
        STAT_EVENT: '/events/statistics/events',
        GET_ALL_ADMIN: '/events/admin/paginated',
        STAT_COMMUNITY: '/events/statistics/communities',
        GET_DOCUMENTS: '/deaths/documents/events/paginated',
        CREATE_OR_UPDATE_DOCUMENTS: '/deaths/documents/events',
        PENALITY_DOWNLOAD_RECEIPT: '/events/{id}/penalities/receipt',
        TYPES : {
            GET_ALL: '/events/types',
            GET_ONE: '/events/types/{id}',
            CREATE: '/events/types',
            UPDATE: '/events/types/{id}', 
        }
    },

    TRANSFERS: {
        GET_ALL: '/transferts/paginated',
        GET_ONE: '/transferts/{id}',
        CREATE: '/transferts',
        CREATE_ADMIN: '/transferts/admin',
        IMPORT_EXCEL: '/transferts/excel',
        PAID: '/transferts/{id}/paid',
        CANCEL: '/transferts/{id}/cancel',
        RESPONSE: '/transferts/{id}/response',
        ADMIN: {
            GET_ALL: '/transferts/admin/paginated',
            RESPONSE: '/transferts/{id}/admin/response',
        },
        UPDATE: '/transferts/{id}',
        DELETE: '/transferts/{id}',
    },
    SECURITY: {
        ROLES: {
            GET_ALL: '/roles/paginated',
            GET_ONE: '/roles/{id}',
            CREATE: '/roles',
            UPDATE: '/roles/{id}',
            DELETE: '/roles/{id}',
        },
        PERMISSIONS: {
            GET_ALL: '/roles/permissions'
        },
        SYSTEM_SETTINGS: {
            GET_ALL: '/system/settings',
            UPDATE: '/system/settings',
        }
    },
    PAYMENT: {
        CREATE_SUBSCRIPTION_TOKEN: '/payments/communities/{reference}/token',
        VALIDATE_SUBSCRIPTION: '/payments/communities/{reference}',
        CREATE_TRANSFER_TOKEN: '/payments/transfers/token',
        VALIDATE_TRANSFER: '/payments/transfers',
        CREATE_CREDIT_TOKEN: '/payments/credits/token',
        VALIDATE_CREDIT: '/payments/credits',
        CREATE_EVENT_TOKEN: '/payments/events/token',
        VALIDATE_EVENT: '/payments/events',
        CREATE_EVENT_PENALITY_TOKEN: '/payments/events/penalities/token',
        VALIDATE_EVENT_PENALITY: '/payments/events/penalities'
    }
}

export default BACKEND_ROUTES
