import dayjs, {Dayjs} from "dayjs"
import { globalT } from "../../lang";
import { formatDate } from "../helpers/funcHelper";
import LogItemKeyEnum from "../enums/LogItemKeyEnum";
import MemberTypeEnum from "../enums/MemberTypeEnum";
import MemberStatusEnum from "../enums/MemberStatusEnum";
import RelationTypeEnum from "../enums/relationTypeEnum";
import TransferStatusEnum from "../enums/TransferStatusEnum";
import { formatMemberStatusToForm, formatMemberTypeToForm, formatRelationTypeToForm, formatTransferStatusToForm } from "../helpers/businessHelper";

export default class LogItem {
    public userName: string
    public oldValue: string
    public newValue: string
    public reason?: string
    public community?: string
    public key: LogItemKeyEnum
    public createdAt: Dayjs

    constructor(data: any) {
        this.userName = data.userName;
        this.reason = data.reason || "";
        this.community = data.community || "-";
        this.oldValue = data.oldValue ? 
            data.key === 'RELATIONSHIP' ? formatRelationTypeToForm(RelationTypeEnum.tryParse(data.oldValue)).label :
            data.key === 'FILIATION' ? formatRelationTypeToForm(RelationTypeEnum.tryParse(data.oldValue)).label :
            data.key === 'TYPE' ? formatMemberTypeToForm(MemberTypeEnum.tryParse(data.oldValue)).label :
            data.key === 'IDENTIFICATION_TYPE' ? globalT(`form.field.id.card.type.${data.oldValue}`) :
            data.key === 'MEMBER_STATUS' ? formatMemberStatusToForm(MemberStatusEnum.tryParse(data.oldValue)).label :
            data.key === 'STATUS' ? formatTransferStatusToForm(TransferStatusEnum.tryParse(data.oldValue)).label :
            data.key === 'DEATH_REQUEST' ? globalT(`static.types.death.request.${data.oldValue}`):
            data.key === 'TELEPHONE' ? data.oldValue :
            dayjs(data.oldValue).isValid() ? formatDate(dayjs(data.oldValue), 'YYYY-MM-DD HH:mm:ss') : data.oldValue : data.oldValue
        this.newValue = data.newValue ? 
            data.key === 'RELATIONSHIP' ? formatRelationTypeToForm(RelationTypeEnum.tryParse(data.newValue)).label :
            data.key === 'FILIATION' ? formatRelationTypeToForm(RelationTypeEnum.tryParse(data.newValue)).label :
            data.key === 'TYPE' ? formatMemberTypeToForm(MemberTypeEnum.tryParse(data.newValue)).label :
            data.key === 'IDENTIFICATION_TYPE' ? globalT(`form.field.id.card.type.${data.newValue}`) :
            data.key === 'MEMBER_STATUS' ? formatMemberStatusToForm(MemberStatusEnum.tryParse(data.newValue)).label :
            data.key === 'STATUS' ? formatTransferStatusToForm(TransferStatusEnum.tryParse(data.newValue)).label :
            data.key === 'DEATH_REQUEST' ? globalT(`static.types.death.request.${data.newValue}`) :
            data.key === 'TELEPHONE' ? data.newValue :
            dayjs(data.newValue).isValid() ? formatDate(dayjs(data.newValue), 'YYYY-MM-DD HH:mm:ss') : data.newValue : data.newValue
        this.key = LogItemKeyEnum.tryParse(data.key)
        this.createdAt = dayjs(data.createdAt)
    }
}
