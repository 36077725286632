export type Metadata = {
    title: string;
    description?: string;
    skipTitleIntl?: boolean;
    titleWithoutAppName?: boolean,
}

export const defaultMetadata: Metadata = {
    title: 'metadata.default.title',
    description: 'metadata.default.desc',
    titleWithoutAppName: true
}

const metadata = {
    auth: {
        login: { title: 'metadata.auth.login.title' }
    },
    errors: {
        404: { title: 'metadata.errors.404.title' }
    },
    dashboard: {
        index: { title: 'metadata.dashboard.index.title' },
        roles: { title: 'metadata.roles.title' },
        permissions: { title: 'metadata.permissions.title' },
        users: { title: 'metadata.users.title' },
        addUser: { title: 'metadata.users.add.title' },
        updateUser: { title: 'metadata.users.update.title' },
        security: {
            systemSettings: { title: 'security.systemSettings.title' }
        },
        adverts: { title: 'business.adverts' },
        partners: { title: 'business.partners' },
        articles: {
            list: { title: 'business.articles.list.title' },
            create: { title: 'business.articles.create.title' },
            update: { title: 'business.articles.update.title' },
        },
        community: {
            self: {
                list: { title: 'communities.list.title' },
                managers: { title: 'community.managers.list' },
            },
            managers: {
                list: { title: 'community.managers.list' },
                create: { title: 'community.managers.create' },
                update: { title: 'community.managers.update' },
            },
            members: {
                list: { title: 'community.members.list' },
                all: { title: 'community.allMembers' },
                create: { title: 'community.members.create' },
                update: { title: 'community.members.update' },
            },
        },
        deaths: {
            list: { title: 'community.death.list.title' },
            documentTypes: { title: 'community.death.documentTypes' },
        },
        finances: {
            contributions: {
                list: { title: 'finances.contributions.list.title' },
                byEvent: { title: 'finances.contributions.by.event.list.title' },
            },
        },
        founders: {
            list: { title: 'business.founders.list.title' },
            create: { title: 'business.founders.create.title' },
            update: { title: 'business.founders.update.title' },
        },
        polls: {
            list: { title: 'polls.list.title' },
            create: { title: 'polls.add.title' },
            update: { title: 'polls.update.title' },
            details: { title: 'polls.details.title' },
        },
        tickets: {
            list: { title: 'business.tickets.list.title' },
            create: { title: 'business.tickets.create.title' },
            update: { title: 'business.tickets.update.title' },
            types: {
                list: { title: 'business.tickets.types.list.title' },
                create: { title: 'business.tickets.types.create.title' },
                update: { title: 'business.tickets.types.update.title' }
            }
        },
        events: {
            list: { title: 'business.event.list.title' },
            create: { title: 'business.event.create.title' },
            update: { title: 'business.event.update.title' },
            types: {
                list: { title: 'business.event.types.list.title' },
                create: { title: 'business.event.types.create.title' },
                update: { title: 'business.event.types.update.title' }
            }
        },
        transfers: {
            list: { title: 'transfers.list' },
            initiate: { title: 'transfers.initiate' },
        },
    }
}

export default metadata
