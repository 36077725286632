import {globalT} from "../../lang";
import MemberTypeEnum from "../enums/MemberTypeEnum";
import LogItemKeyEnum from "../enums/LogItemKeyEnum";
import EventStatusEnum from "../enums/EventStatusEnum";
import DocumentTypeEnum from "../enums/DocumentTypeEnum";
import MemberStatusEnum from "../enums/MemberStatusEnum";
import TransferTypeEnum from "../enums/TransferTypeEnum";
import RelationTypeEnum from "../enums/relationTypeEnum";
import TicketStatusEnum from "../enums/TicketStatusEnum";
import TransferStatusEnum from "../enums/TransferStatusEnum";
import CommunityStatusEnum from "../enums/CommunityStatusEnum";
import DeathMemberStatusEnum from "../enums/DeathMemberStatusEnum";
import ContributionStatusEnum from "../enums/ContributionStatusEnum";
import TransactionDirectionEnum from "../enums/TransactionDirectionEnum";

export const formatRelationTypeToForm = (relationTypeEnum: RelationTypeEnum) => {
    return ({ label: globalT(`static.types.relation.type.${relationTypeEnum.enumKey}`), value: relationTypeEnum });
}

export const formatCommunityStatusToForm = (communityStatusEnum: CommunityStatusEnum) => {
    return ({ label: globalT(`status.${communityStatusEnum.enumKey}`), value: communityStatusEnum });
}

export const formatEventStatusToForm = (eventStatusEnum: EventStatusEnum) => {
    return ({ label: globalT(`status.${eventStatusEnum.enumKey}`), value: eventStatusEnum });
}

export const formatTicketStatusToForm = (ticketStatusEnum: TicketStatusEnum) => {
    return ({ label: globalT(`status.${ticketStatusEnum.enumKey}`), value: ticketStatusEnum });
}

export const formatMemberTypeToForm = (memberTypeEnum: MemberTypeEnum) => {
    return ({ label: globalT(`static.types.member.type.${memberTypeEnum.enumKey}`), value: memberTypeEnum });
}

export const formatMemberStatusToForm = (memberStatusEnum: MemberStatusEnum) => {
    return ({ label: globalT(`static.types.member.status.${memberStatusEnum.enumKey}`), value: memberStatusEnum });
}

export const formatDeathMemberStatusToForm = (deathMemberStatusEnum: DeathMemberStatusEnum) => {
    return ({ label: globalT(`static.types.death.request.${deathMemberStatusEnum.value}`), value: deathMemberStatusEnum })
}

export const formatTransferStatusToForm = (transferStatusEnum: TransferStatusEnum) => {
    return ({ label: globalT(`static.types.transfer.status.${transferStatusEnum.enumKey}`), value: transferStatusEnum });
}

export const formatTransferTypeToForm = (transferTypeEnum: TransferTypeEnum) => {
    return ({ label: globalT(`static.types.transfer.type.${transferTypeEnum.enumKey}`), value: transferTypeEnum });
}

export const formatContributionStatusToForm = (contributionStatusEnum: ContributionStatusEnum) => {
    return ({ label: globalT(`status.${contributionStatusEnum.enumKey}`), value: contributionStatusEnum });
}

export const formatTransactionDirectionToForm = (transactionDirectionEnum: TransactionDirectionEnum) => {
    return ({ label: globalT(`static.types.transaction.direction.${transactionDirectionEnum.enumKey}`), value: transactionDirectionEnum });
}

export const formatLogItemKeyToForm = (logItemKeyEnum: LogItemKeyEnum) => {
    return ({ label: globalT(`static.types.logs.key.${logItemKeyEnum.enumKey}`), value: logItemKeyEnum });
}

export const formatDocumentTypeToForm = (documentTypeEnum: DocumentTypeEnum) => {
    return ({ label: globalT(`business.documents.type.${documentTypeEnum.enumKey}.list.title`), value: documentTypeEnum });
}
