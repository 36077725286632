import {AxiosInstance, AxiosResponse} from "axios";

import {
    AxiosRequestConfigExtended, LoginRequest, RegistrationManager, RPNClientApiPaginatedResponse,
} from './network.types';
import config from '../config';
import BACKEND_ROUTES from '../commons/urls/back'
import {mapDataToQueryString, toSnakeCase} from '../commons/helpers/funcHelper';
import AuthUser from "../commons/models/AuthUser";
import { globalT } from "../lang";
import SystemSettings from "../commons/models/SystemSettings";
import Role from "../commons/models/Role";
import Permission from "../commons/models/Permission";
import Manager from "../commons/models/Manager";
import Member from "../commons/models/Member";
import MemberTypeEnum from "../commons/enums/MemberTypeEnum";
import {
    formatContributionStatusToForm,
    formatMemberStatusToForm,
    formatCommunityStatusToForm,
    formatMemberTypeToForm, formatRelationTypeToForm, formatTransferStatusToForm,
    formatTransferTypeToForm
} from "../commons/helpers/businessHelper";
import MemberStatusEnum from "../commons/enums/MemberStatusEnum";
import Transfer from "../commons/models/Transfer";
import Community from "../commons/models/Community";
import TransferTypeEnum from "../commons/enums/TransferTypeEnum";
import TransferStatusEnum from "../commons/enums/TransferStatusEnum";
import RelationTypeEnum from "../commons/enums/relationTypeEnum";
import DeathDocumentType from "../commons/models/DeathDocumentType";
import DeathRequest from "../commons/models/DeathRequest";
import DeathDocument from "../commons/models/DeathDocument";
import Contribution from "../commons/models/Contribution";
import Transaction from "../commons/models/Transaction";
import DeathEvent from "../commons/models/DeathEvent";
import Poll, {PollItem, PollOption} from "../commons/models/Poll";
import Document from "../commons/models/Document";
import Article from "../commons/models/Article";
import Comment from "../commons/models/Comment";
import Founder from "../commons/models/Founder";
import Ticket from "../commons/models/Ticket";
import Advert from "../commons/models/Advert";
import Partner from "../commons/models/Partner";
import MinimalMember from "../commons/models/MinimalMember";
import TicketType from "../commons/models/TicketType";
import LogItem from "../commons/models/LogItem";
import ContributionStatusEnum from "../commons/enums/ContributionStatusEnum";
import EventStat from "../commons/models/EventStat";
import CommunityDetails from "../commons/models/CommunityDetails";
import MemberDeleted from "../commons/models/MemberDeleted";
import DeathMemberStatusEnum from "../commons/enums/DeathMemberStatusEnum";
import { logout } from "../store/slices/authUser/actions";
import { AUTH, CONDITIONS, DEATHS, HOME } from "../commons/urls/front";
import CommunityLight from "../commons/models/CommunityLight";
import CommunityStatusEnum from "../commons/enums/CommunityStatusEnum";
import EventType from "../commons/models/EventType";
import Event from "../commons/models/Event";
import EventPresence from "../commons/models/EventPresence";
import EventPresenceStat from "../commons/models/EventPresenceStat";
import EventDocument from "../commons/models/EventDocument";

export default class Client {
    public instance: AxiosInstance;

    constructor(instance: AxiosInstance) {
        this.instance = instance;
    }

    /**
     * The rf_epay gateway
     * @param verb
     * @param url
     * @param data
     * @param config object which might have following keys:
     *                  noFormData: {boolean} Indicate whether or not to parse data into form data. Default to false
     *                  fileData: {Array<string>} List all files object of the request data
     *                  multipart: {boolean} Indicate if the request contains files thus multi-part form
     *                  shouldSkipToken: {boolean} Indicate if we do not have to bind token to the request
     * @return {Promise<unknown>}
     */
    makeRequest = <ResourceType = any>(
        verb: 'get' | 'post' | 'put' | 'patch' | 'delete',
        url: string,
        data: any = null,
        // @ts-ignore
        config: Partial<AxiosRequestConfigExtended> = {}
    ): Promise<AxiosResponse<ResourceType, AxiosRequestConfigExtended>> => {
        return new Promise((resolve, reject) => {
            let _url = url; let _config = config; let _data = data;
            if ((verb === 'get' || verb === 'delete') && data) {
                _url = mapDataToQueryString({
                    data,
                    url,
                    transformToUrl: true,
                    removeTrashValue: config.removeTrashValueRequest === undefined ? true : config.removeTrashValueRequest,
                    transformToSnakeCase: Boolean(!config.skipSnakeCaseTransformRequest)
                }).url;
            }

            if (!config.skipAutoFormDataTransform && (verb === 'post' || verb === 'put')) {
                if (!_config.skipSnakeCaseTransform) {
                    _data = toSnakeCase(_data);
                }
                if (!_config.noFormData) {
                    // @ts-ignore
                    _config.headers = {
                        ...(_config.headers || {}),
                        'Content-type': 'multipart/form-data',
                        Accept: 'application/json',
                    }
                }
            }

            const params =
                verb === 'get' || verb === 'delete'
                    ? [_url, config]
                    : [_url, _data, config];
            // @ts-ignore
            this.instance[verb](...params)
                .then((result) => resolve(result))
                .catch((error) =>
                    reject(
                        error || new Error('An error occurred while fetching'),
                    ),
                );
        });
    };

    /**
     * Bind params to a given url
     * @param to
     * @param params
     * @param withFullUrl
     */
    joinUrlWithParams = (to: string, params: Array<{ param: string; value: any }>, withFullUrl = false) => {
        let url = withFullUrl ? `${config.rpnApiUrl}${to}` : to;
        params.forEach(param => {
            url = url.replace(`{${param.param}}`, `${encodeURIComponent(param.value)}`);
        });

        return url;
    };

    /**
     * Shortcut of joinUrlWithParams for Id
     * @param to
     * @param id
     * @param withFullUrl
     */
    joinUrlWithParamsId = (to: string, id: string | number, withFullUrl = false) => this.joinUrlWithParams(to, [{
        param: 'id',
        value: id
    }], withFullUrl);

    auth = {
        login: (data: LoginRequest) => this.makeRequest<{ token: string }>(
            'post',
            BACKEND_ROUTES.AUTH.LOGIN,
            {
                username: data.login,
                password: data.password,
            },
            {
            // @ts-ignore
            headers: {
                'Content-type': 'multipart/form-data',
                Accept: 'application/json',
            },
            shouldSkipToken: true,
            skipAllErrors: true,
            shouldSkipDataParsing: true,
        }),
        loginSetCredentials: (data: LoginRequest, reference: string) => this.makeRequest(
            'post',
            this.joinUrlWithParamsId(BACKEND_ROUTES.AUTH.USERS.CREDENTIALS, reference),
            data,
            { skipErrorCodes: [409, 404], shouldSkipToken: true, }
        ),
        getDetails: () => {
            return new Promise<AxiosResponse<AuthUser, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<AuthUser>('get', BACKEND_ROUTES.AUTH.ME, null, { skipAllErrors: true } )
                .then((resp) => {
                    // If the user doesn't have a role, we log him out
                    if (!resp.data.role) {
                        reject(new Error('Roles not found'))
                    } else {
                        resolve({
                            ...resp,
                            data: new AuthUser(resp.data)
                        })
                    }

                })
                .catch(err => {
                    logout();
                    const unauthRoutes: string[] = [
                        AUTH.FORGOT_PASSWORD.RESET,
                        AUTH.FORGOT_PASSWORD.INDEX,
                        AUTH.REGISTER.SET_CREDENTIALS,
                        AUTH.REGISTER.INDEX,
                        AUTH.LOGIN,
                        DEATHS.UPLOAD_DOCUMENTS,
                        CONDITIONS.CGU,
                        CONDITIONS.CONVENTION,
                        HOME
                    ]
                    // console.log(window.location.pathname, !unauthRoutes.includes(window.location.pathname), unauthRoutes)
                    if(!unauthRoutes.includes(window.location.pathname)) {
                        window.location.href = AUTH.LOGIN;
                    }
                    reject(err)
                })
            })
        },
        getStatisctics: () => {
            return new Promise<AxiosResponse<any, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<any>('get', BACKEND_ROUTES.AUTH.STATISTICS, null, { skipAllErrors: true } )
                .then((resp) => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
            })
        },
        register: {
            communities: {
                create: (data: any) => {
                    return new Promise<AxiosResponse<Required<RegistrationManager['community']>, AxiosRequestConfigExtended>>((resolve, reject) => {
                        this.makeRequest<Required<RegistrationManager['community']>>(
                            'post',
                            BACKEND_ROUTES.AUTH.REGISTER.COMMUNITIES.CREATE,
                            data,
                            {skipErrorCodes: [409]}
                        )
                            .then((resp) => {
                                const _data = new RegistrationManager({ community: resp.data })
                                resolve({
                                    ...resp,
                                    data: _data.community
                                })
                            })
                            .catch(err => reject(err))
                    })
                },
                acceptCGU: (communityReference: string) => this.makeRequest<Required<RegistrationManager['community']>>(
                    'post',
                    BACKEND_ROUTES.AUTH.REGISTER.COMMUNITIES.ACCEPT_CONDITIONS,
                    { communityReference },
                    { skipErrorCodes: [409] }
                )
            },
            managers: {
                create: (data: any) => {
                    return new Promise<AxiosResponse<RegistrationManager, AxiosRequestConfigExtended>>((resolve, reject) => {
                        this.makeRequest<RegistrationManager>(
                            'post',
                            BACKEND_ROUTES.AUTH.REGISTER.MANAGERS.CREATE,
                            data,
                            { skipErrorCodes: [409] }
                        )
                            .then((resp) => resolve({
                                ...resp,
                                data: new RegistrationManager(resp.data)
                            }))
                            .catch(err => reject(err))
                    })
                },
                getOne: (registrationManagerId: string) => {
                    return new Promise<AxiosResponse<RegistrationManager, AxiosRequestConfigExtended>>((resolve, reject) => {
                        this.makeRequest<RegistrationManager>(
                            'get',
                            this.joinUrlWithParamsId(BACKEND_ROUTES.AUTH.REGISTER.MANAGERS.GET_ONE, registrationManagerId),
                            { managerReference: registrationManagerId },
                            { skipErrorCodes: [404] })
                            .then((resp) => resolve({
                                ...resp,
                                data: new RegistrationManager(resp.data)
                            }))
                            .catch(err => reject(err))
                    })
                },
                emailChecking: {
                    resendCode: (managerId: string) => this.makeRequest<undefined>(
                        'get',
                        this.joinUrlWithParamsId(BACKEND_ROUTES.AUTH.REGISTER.MANAGERS.EMAIL_CHECKING.RESEND_CODE, managerId),
                    ),
                    checkCode: (managerId: string, code: string) => this.makeRequest<undefined>(
                        'post',
                        this.joinUrlWithParamsId(BACKEND_ROUTES.AUTH.REGISTER.MANAGERS.EMAIL_CHECKING.CHECK_CODE, managerId),
                        {code},
                        { skipErrorCodes: [400] })
                }
            },
        },
        forgotPassword: {
            sendLink: (email: string) => this.makeRequest(
                'post',
                BACKEND_ROUTES.AUTH.USERS.FORGOT_PASSWORD.INIT,
                { email },
                { skipErrorCodes: [404] }
            ),
            confirm: (reference: string, password: string) => this.makeRequest(
                'post',
                BACKEND_ROUTES.AUTH.USERS.FORGOT_PASSWORD.CONFIRM,
                { code: reference, password },
                { skipErrorCodes: [404] }
            )
        },
        users: {
            password: (data: {oldPassword: string, newPassword: string}) => this.makeRequest(
                'put',
                BACKEND_ROUTES.AUTH.USERS.PASSWORD,
                data
            )
        },
    }

    partners = {
        getAll: () => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Partner[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Partner[]>('get', BACKEND_ROUTES.PARTNERS.GET_ALL, null)
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            pageable: {
                                pageNumber: 1,
                                pageSize: 10,
                            },
                            totalPages: 1,
                            totalElements: resp.data.length,
                            content: resp.data.map(d => new Partner(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        create: (data: any, fileData: string[]) => this.makeRequest(
            'post',
            BACKEND_ROUTES.PARTNERS.CREATE,
            data,
            { skipAutoFormDataTransform: true, fileData: fileData }
        ),
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(BACKEND_ROUTES.PARTNERS.DELETE, id)),
    }

    adverts = {
        getAll: (filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Advert[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<Advert[]>>(
                    'get',
                    BACKEND_ROUTES.ADVERTS.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new Advert(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string | number) => {
            return new Promise<AxiosResponse<Advert, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Advert>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.ADVERTS.GET_ONE, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Advert(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        create: (data: any, fileData: string[]) => this.makeRequest(
            'post',
            BACKEND_ROUTES.ADVERTS.CREATE,
            data,
            { skipAutoFormDataTransform: true, fileData: fileData }
        ),
        update: (id: string, data: any, fileData?: string[]) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.ADVERTS.UPDATE, id),
            data,
            fileData !== undefined
                ? { skipAutoFormDataTransform: true, fileData }
                : { }
        ),
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(BACKEND_ROUTES.ADVERTS.DELETE, id)),
    }

    articles = {
        getAll: (filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Article[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<Article[]>>(
                    'get',
                    BACKEND_ROUTES.ARTICLES.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new Article(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string | number) => {
            return new Promise<AxiosResponse<Article, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Article>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.ARTICLES.GET_ONE, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Article(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        create: (data: any, fileData: string[]) => this.makeRequest(
            'post',
            BACKEND_ROUTES.ARTICLES.CREATE,
            data,
            { skipAutoFormDataTransform: true, fileData: fileData }
        ),
        update: (id: string, data: any, fileData?: string[]) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.ARTICLES.UPDATE, id),
            data,
            fileData !== undefined
                ? { skipAutoFormDataTransform: true, fileData }
                : { }
        ),
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(BACKEND_ROUTES.ARTICLES.DELETE, id)),
    }

    community = {
        self: {
            getAll: (filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Community[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<Community[]>>('get', BACKEND_ROUTES.COMMUNITY.SELF.GET_ALL, filterParams || null)
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new Community(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            downloadExcel: (filterParams: object) => this.makeRequest(
                'get',
                BACKEND_ROUTES.COMMUNITY.SELF.DOWNLOAD_EXCEL,
                filterParams || null,
            ),
            getAllSimple: (filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<CommunityLight[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<CommunityLight[]>>('get', BACKEND_ROUTES.COMMUNITY.SELF.GET_ALL_SIMPLE, filterParams || null)
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new CommunityLight(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            getOne: (id: string) => {
                return new Promise<AxiosResponse<CommunityDetails, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<CommunityDetails>('get', this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.SELF.OVERVIEW, id))
                        .then((resp) => resolve({
                            ...resp,
                            data: new CommunityDetails(resp.data)
                        }))
                        .catch(err => reject(err))
                })
            },
            getManagers: (data: {reference: string}) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Manager[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<Manager[]>('get', BACKEND_ROUTES.COMMUNITY.MANAGERS.GET_ALL_BY_COMMUNITY, data)
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                pageable: {
                                    pageNumber: 1,
                                    pageSize: 10,
                                },
                                totalPages: 1,
                                totalElements: resp.data.length,
                                content: resp.data.map(d => new Manager(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            update: (data: any) => this.makeRequest(
                'put',
                BACKEND_ROUTES.COMMUNITY.SELF.UPDATE_COMMUNITY,
                data
            ),
            getActive: () => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Community[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<Community[]>('get', BACKEND_ROUTES.COMMUNITY.SELF.GET_ACTIVE, null)
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                pageable: {
                                    pageNumber: 1,
                                    pageSize: 10,
                                },
                                totalPages: 1,
                                totalElements: resp.data.length,
                                content: resp.data.map(d => new Community(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            activate: (id: string, reason?: string) => this.makeRequest('put', this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.SELF.ACTIVATE, id), reason ? {reason} : {}),
            block: (id: string, reason?: string) => this.makeRequest('put', this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.SELF.BLOCK, id), reason ? {reason} : {}),
            managers: (id: string, filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Manager[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<Manager[]>>('get', this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.MANAGERS.GET_BY_COMMUNITY, id), filterParams || null)
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new Manager(d)),
                            }
                        }))
                        .catch(err => {
                            reject(err)
                        })
                })
            }
        },
        managers: {
            getAll: (filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Manager[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<Manager[]>>('get', BACKEND_ROUTES.COMMUNITY.MANAGERS.GET_ALL, filterParams || null)
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new Manager(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            getOne: (id: string) => {
                return new Promise<AxiosResponse<Manager, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<Manager>('get', this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.MANAGERS.GET_ONE, id))
                        .then((resp) => resolve({
                            ...resp,
                            data: new Manager(resp.data)
                        }))
                        .catch(err => reject(err))
                })
            },
            create: (data: any) => this.makeRequest('post', BACKEND_ROUTES.COMMUNITY.MANAGERS.CREATE, data),
            update: (id: string, data: any) => this.makeRequest(
                'put',
                this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.MANAGERS.UPDATE, id),
                data,
            ),
            changeStatus: (id: string, data: any) => this.makeRequest(
                'put',
                this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.MANAGERS.BLOCK, id),
                data,
            ),
            delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.MANAGERS.DELETE, id)),
        },
        members: {
            getAll: (isAdmin: boolean, filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Member[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<Member[]>>(
                        'get',
                        isAdmin ? BACKEND_ROUTES.COMMUNITY.MEMBERS.ADMIN.GET_ALL : BACKEND_ROUTES.COMMUNITY.MEMBERS.GET_ALL,
                        filterParams || null,
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new Member(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            getAllRpn: (filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<MinimalMember[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<MinimalMember[]>>(
                        'get',
                        BACKEND_ROUTES.COMMUNITY.MEMBERS.GET_RPN,
                        filterParams || null,
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new MinimalMember(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            getFamilyResponsibles: (isAdmin: boolean, data: {community_reference?: string} = {}) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Member[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<Member[]>('get', BACKEND_ROUTES.COMMUNITY.MEMBERS.RESPONSIBLES, data)
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                pageable: {
                                    pageNumber: 1,
                                    pageSize: 10,
                                },
                                totalPages: 1,
                                totalElements: resp.data.length,
                                content: resp.data.map(d => new Member(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            getLogs: (reference: string) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<LogItem[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<LogItem[]>('get', this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.MEMBERS.LOGS, reference))
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                pageable: {
                                    pageNumber: 1,
                                    pageSize: 10,
                                },
                                totalPages: 1,
                                totalElements: resp.data.length,
                                content: resp.data.map(d => new LogItem(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },  
            getDoublons: (data: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<MinimalMember[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<MinimalMember[]>>(
                        'get',
                        BACKEND_ROUTES.COMMUNITY.MEMBERS.GET_DOUBLONS,
                        data,
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new MinimalMember(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            getOne: (id: string) => {
                return new Promise<AxiosResponse<Member, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<Member>('get', this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.MEMBERS.GET_ONE, id))
                        .then((resp) => resolve({
                            ...resp,
                            data: new Member(resp.data)
                        }))
                        .catch(err => reject(err))
                })
            },
            delete: (id: string, data: any) => this.makeRequest(
                'delete',
                this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.MEMBERS.DELETE, id),
                data,
            ),
            getDeleted: () => {
                return new Promise<AxiosResponse<MemberDeleted[], AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<MemberDeleted[]>(
                        'get',
                        BACKEND_ROUTES.COMMUNITY.MEMBERS.GET_DELETED,
                    )
                    .then((resp) => resolve({
                        ...resp,
                        data: resp.data.map(d => new MemberDeleted(d))
                    }))
                    .catch(err => reject(err))
                })
            },
            create: (data: any, fileData: string[]) => this.makeRequest(
                'post',
                BACKEND_ROUTES.COMMUNITY.MEMBERS.CREATE,
                data,
                { skipAutoFormDataTransform: true, fileData: fileData }
            ),
            update: (id: string, data: any, fileData: string[]) => this.makeRequest(
                'put',
                this.joinUrlWithParamsId(BACKEND_ROUTES.COMMUNITY.MEMBERS.UPDATE, id),
                data,
                { skipAutoFormDataTransform: true, fileData: fileData }
            ),
            activate: (id: string, reason: string, activate: boolean) => this.makeRequest('put', BACKEND_ROUTES.COMMUNITY.MEMBERS.ACTIVATE, { reference: id, reason, activate }, { skipErrorCodes: [400] }),
            downloadExcel: (isAdmin: boolean, filterParams: object) => this.makeRequest(
                'get',
                isAdmin ? BACKEND_ROUTES.COMMUNITY.MEMBERS.ADMIN.DOWNLOAD_EXCEL : BACKEND_ROUTES.COMMUNITY.MEMBERS.DOWNLOAD_EXCEL,
                filterParams || null,
            ),
            importExcel: (data: any, fileData: string[]) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<{line: string, description: string}[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<{description: string}[]>(
                        'post',
                        BACKEND_ROUTES.COMMUNITY.MEMBERS.IMPORT_EXCEL,
                        data,
                        { skipAutoFormDataTransform: true, fileData: fileData }
                    )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            pageable: {
                                pageNumber: 1,
                                pageSize: 10,
                            },
                            totalPages: 1,
                            totalElements: resp.data.length,
                            content: resp.data.map(d => { return {line: d.description.split(':')[0], description: d.description.split(':')[1].trim()}}),
                        }
                    }))
                    .catch(err => reject(err))
                })
            }
        }
    }
    
    deaths = {
        getAll: (isAdmin: boolean, filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<DeathRequest[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<DeathRequest[]>>(
                    'get',
                    isAdmin ? BACKEND_ROUTES.DEATHS.ADMIN.GET_ALL : BACKEND_ROUTES.DEATHS.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new DeathRequest(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        getOneByMemberId: (memberId: string) => {
            return new Promise<AxiosResponse<DeathRequest, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<DeathRequest>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.GET_ONE_BY_MEMBER_ID, memberId),
                    // { memberReference: memberId },
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new DeathRequest(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        generateNotice: (reference: string) => {
            return new Promise<AxiosResponse<string, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<string>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.GENERATE_NOTICE, reference),
                    // { memberReference: memberId },
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: resp.data
                    }))
                    .catch(err => reject(err))
            })
        },
        findComment: (reference: string) => {
            return new Promise<AxiosResponse<Comment, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Comment>(
                    'get',
                    BACKEND_ROUTES.DEATHS.COMMENTS.FIND,
                    {event_reference: reference}
                )
                .then((resp) => resolve({
                    ...resp,
                    data: new Comment(resp.data)
                }))
                .catch(err => reject(err))
            })
        },
        saveComment: (reference: string, content: string) => {
            return new Promise<AxiosResponse<Comment, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Comment>(
                    'post',
                    BACKEND_ROUTES.DEATHS.COMMENTS.CREATE,
                    { event_reference: reference, content }
                )
                .then((resp) => resolve({
                    ...resp,
                    data: new Comment(resp.data)
                }))
                .catch(err => reject(err))
            })
        },
        updateComment: (reference: string) => {
            return new Promise<AxiosResponse<Comment, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Comment>(
                    'put',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.COMMENTS.UPDATE, reference),
                    {}
                )
                .then((resp) => resolve({
                    ...resp,
                    data: new Comment(resp.data)
                }))
                .catch(err => reject(err))
            })
        },
        getComments: (filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Comment[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<Comment[]>>(
                    'get',
                    BACKEND_ROUTES.DEATHS.COMMENTS.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new Comment(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        create: (data: any, fileData: string[]) => {
            return new Promise<AxiosResponse<DeathRequest, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<DeathRequest>(
                    'post',
                    BACKEND_ROUTES.DEATHS.CREATE,
                    data,
                    { skipAutoFormDataTransform: true, fileData: fileData }
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new DeathRequest(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        update: (reference: string, data: any, fileData: string[]) => {
            return new Promise<AxiosResponse<DeathRequest, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<DeathRequest>(
                    'post',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.UPDATE, reference),
                    data,
                    { skipAutoFormDataTransform: true, fileData: fileData }
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new DeathRequest(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        response: (id: string, data: object) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.RESPONSE, id),
            data,
            { skipErrorCodes: [409] }
        ),
        accept: (id: string, data: object) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.ACCEPT, id),
            data,
            { skipErrorCodes: [409] }
        ),
        addDocuments: (id: string, data: object) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.ADD_DOCUMENTS, id),
            data,
            { skipErrorCodes: [409] }
        ),
        cancel: (id: string, data: object) => this.makeRequest('put', this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.CANCEL, id), data),
        approveDocuments: (id: string) => this.makeRequest('put', this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.APPROVE_DOCUMENTS, id), {}),
        getDocuments: (deathId: string, filterParams?: object) => {
            return new Promise<AxiosResponse<DeathDocument[], AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<DeathDocument[]>(
                    'get',
                    BACKEND_ROUTES.DEATHS.GET_DOCUMENTS,
                    { deathReference: deathId, ...(filterParams || {}) },
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: resp.data.map(d => new DeathDocument(d))
                    }))
                    .catch(err => reject(err))
            })
        },
        getPublicDocuments: (deathId: string, filterParams?: object) => {
            return new Promise<AxiosResponse<DeathDocument[], AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<DeathDocument[]>(
                    'get',
                    BACKEND_ROUTES.DEATHS.GET_PUBLIC_DOCUMENTS,
                    { deathReference: deathId, ...(filterParams || {}) },
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: resp.data.map(d => new DeathDocument(d))
                    }))
                    .catch(err => reject(err))
            })
        },
        createOrUpdateDocument: (data: any, fileData: string[]) => this.makeRequest<DeathDocument[]>(
            'post',
            BACKEND_ROUTES.DEATHS.CREATE_OR_UPDATE_DOCUMENTS,
            data,
            { skipAutoFormDataTransform: true, fileData }
        ),
        createOrUpdatePublicDocument: (data: any, fileData: string[]) => this.makeRequest<DeathDocument[]>(
            'post',
            BACKEND_ROUTES.DEATHS.CREATE_OR_UPDATE_PUBLIC_DOCUMENTS,
            data,
            { skipAutoFormDataTransform: true, fileData }
        ),
        documentTypes: {
            getAll: (paginate: boolean, filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<DeathDocumentType[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<DeathDocumentType[]>>(
                        'get',
                        paginate ? BACKEND_ROUTES.DEATHS.DOCUMENT_TYPES.GET_ALL : BACKEND_ROUTES.DEATHS.DOCUMENT_TYPES.GET_ALL_NOT_PAGINATED,
                        filterParams || null,
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: paginate
                                    ? resp.data.content.map(d => new DeathDocumentType(d)) // @ts-ignore
                                    : resp.data.map(d => new DeathDocumentType(d))
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            create: (data: any, fileData: string[]) => this.makeRequest(
                'post',
                BACKEND_ROUTES.DEATHS.DOCUMENT_TYPES.CREATE,
                data,
                { skipAutoFormDataTransform: true, fileData: fileData }
            ),
            update: (id: string, data: any) => this.makeRequest(
                'put',
                this.joinUrlWithParamsId(BACKEND_ROUTES.DEATHS.DOCUMENT_TYPES.UPDATE, id),
                data,
                data.specimen !== undefined
                    ? { skipAutoFormDataTransform: true, fileData: ['specimen'] }
                    : { }
            ),
        }
    }

    documents = {
        getAll: (isAdmin: boolean, filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Document[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<Document[]>>(
                    'get',
                    isAdmin ? BACKEND_ROUTES.DOCUMENTS.ADMIN.GET_ALL : BACKEND_ROUTES.DOCUMENTS.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new Document(d)),
                        }
                    }))
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        create: (data: object, fileData: string[]) => this.makeRequest(
            'post',
            BACKEND_ROUTES.DOCUMENTS.CREATE,
            data,
            { skipAutoFormDataTransform: true, fileData }
        ),
        update: (id: string, data: object, fileData?: string[]) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.DOCUMENTS.UPDATE, id),
            data,
            fileData ? { skipAutoFormDataTransform: true, fileData } : {}
        ),
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(BACKEND_ROUTES.DOCUMENTS.DELETE, id), {}),
    }

    finances = {
        contributions: {
            getAll: (filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Contribution[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<Contribution[]>>(
                        'get',
                        BACKEND_ROUTES.FINANCES.CONTRIBUTIONS.GET_ALL,
                        filterParams || null,
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new Contribution(d))
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            
            updateDeadline: (reference: string, deadline: string) => this.makeRequest('put', this.joinUrlWithParamsId(BACKEND_ROUTES.FINANCES.CONTRIBUTIONS.UPDATE_DEADLINE, reference), {deadline}),
            paid: (token: string, references: string) => this.makeRequest('post', BACKEND_ROUTES.PAYMENT.VALIDATE_EVENT, {token, references}),
            downloadExcel: (isAdmin: boolean, filterParams: object) => this.makeRequest(
                'get',
                isAdmin ? BACKEND_ROUTES.FINANCES.CONTRIBUTIONS.ADMIN.DOWNLOAD_EXCEL : BACKEND_ROUTES.FINANCES.CONTRIBUTIONS.DOWNLOAD_EXCEL,
                filterParams || null,
            ),
            downloadReceipt: (reference: string) => this.makeRequest(
                'get',
                this.joinUrlWithParamsId(BACKEND_ROUTES.FINANCES.CONTRIBUTIONS.DOWNLOAD_RECEIPT, reference),
                null,
            ),
            createPaymentToken: (data: {references: string, description?: string|null, is_manual_payment?: boolean, receivedDate?: string}) => this.makeRequest('post', BACKEND_ROUTES.PAYMENT.CREATE_EVENT_TOKEN, data, { removeTrashValueRequest: true, skipErrorCodes: [412] }),
            admin: {
                getAll: (filterParams: object) => {
                    return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<DeathEvent[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                        this.makeRequest<RPNClientApiPaginatedResponse<DeathEvent[]>>(
                            'get',
                            BACKEND_ROUTES.FINANCES.CONTRIBUTIONS.ADMIN.GET_ALL,
                            filterParams || null,
                        )
                            .then((resp) => resolve({
                                ...resp,
                                data: {
                                    ...resp.data,
                                    content: resp.data.content.map(d => new DeathEvent(d))
                                }
                            }))
                            .catch(err => reject(err))
                    })
                },
                statistics: (filterParams: object) => {
                    return new Promise<AxiosResponse<EventStat, AxiosRequestConfigExtended>>((resolve, reject) => {
                        this.makeRequest<EventStat>(
                            'get',
                            BACKEND_ROUTES.FINANCES.CONTRIBUTIONS.ADMIN.STATS,
                            filterParams || null,
                        )
                            .then((resp) => resolve({
                                ...resp
                            }))
                            .catch(err => reject(err))
                    })
                },
                getAllByEvent: (eventId: string, filterParams: object) => {
                    return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Contribution[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                        this.makeRequest<RPNClientApiPaginatedResponse<Contribution[]>>(
                            'get',
                            this.joinUrlWithParamsId(BACKEND_ROUTES.FINANCES.CONTRIBUTIONS.ADMIN.GET_ALL_BY_EVENT, eventId),
                            filterParams || null,
                        )
                            .then((resp) => resolve({
                                ...resp,
                                data: {
                                    ...resp.data,
                                    content: resp.data.content.map(d => new Contribution(d))
                                }
                            }))
                            .catch(err => reject(err))
                    })
                },
            }
        },
        wallets: {
            balance: () => {
                return new Promise<AxiosResponse<{ balance: number }, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<{ balance: number }>(
                        'get',
                        BACKEND_ROUTES.FINANCES.WALLETS.GET_ONE,
                        {},
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                balance: resp.data.balance
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            recharge: (data: {token: string, reference?: string}) => this.makeRequest('post', BACKEND_ROUTES.PAYMENT.VALIDATE_CREDIT, data),
            createPaymentToken: (datas: {description?: string|null, amount?: number, reference?: string|null, is_manual_payment?: boolean, receivedDate?: string}) => this.makeRequest('post', BACKEND_ROUTES.PAYMENT.CREATE_CREDIT_TOKEN, datas, { removeTrashValueRequest: true, skipErrorCodes: [412] }),
            transactions: {
                getAll: (filterParams: object) => {
                    return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Transaction[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                        this.makeRequest<RPNClientApiPaginatedResponse<Transaction[]>>(
                            'get',
                            BACKEND_ROUTES.FINANCES.WALLETS.TRANSACTIONS,
                            filterParams || null,
                        )
                            .then((resp) => resolve({
                                ...resp,
                                data: {
                                    ...resp.data,
                                    content: resp.data.content.map(d => new Transaction(d))
                                }
                            }))
                            .catch(err => reject(err))
                    })
                }
            },
        }
    }

    founders = {
        getAll: (filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Founder[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<Founder[]>>(
                    'get',
                    BACKEND_ROUTES.FOUNDERS.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new Founder(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string | number) => {
            return new Promise<AxiosResponse<Founder, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Founder>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.FOUNDERS.GET_ONE, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Founder(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        create: (data: any, fileData: string[]) => this.makeRequest(
            'post',
            BACKEND_ROUTES.FOUNDERS.CREATE,
            data,
            { skipAutoFormDataTransform: true, fileData: fileData }
        ),
        update: (id: string, data: any, fileData?: string[]) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.FOUNDERS.UPDATE, id),
            data,
            fileData !== undefined
                ? { skipAutoFormDataTransform: true, fileData }
                : { }
        ),
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(BACKEND_ROUTES.FOUNDERS.DELETE, id)),
    }

    polls = {
        getAll: (isAdmin: boolean, filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Poll[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<Poll[]>>(
                    'get',
                    isAdmin ? BACKEND_ROUTES.POLLS.ADMIN.GET_ALL : BACKEND_ROUTES.POLLS.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new Poll(d)),
                        }
                    }))
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getOne: (id: string) => {
            return new Promise<AxiosResponse<Poll, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Poll>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.GET_ONE, id),
                {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Poll(resp.data)
                    }))
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        findDetails: (id: string) => {
            return new Promise<AxiosResponse<Poll, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Poll>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.FIND_DETAILS, id),
                {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Poll(resp.data)
                    }))
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        create: (data: object) => {
            return new Promise<AxiosResponse<Poll, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest(
                    'post',
                    BACKEND_ROUTES.POLLS.CREATE,
                    data
                )
                .then((resp) => resolve({
                    ...resp,
                    data: new Poll(resp.data)
                }))
                .catch((err) => reject(err))
            })
        },
        update: (id: string, data: object) => this.makeRequest('put', this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.UPDATE, id), data),
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.DELETE, id), {}),
        publish: (id: string) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.ACTIVATE, id),
            {},
            { skipErrorCodes: [409] }
        ),        
        publishResults: (id: string) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.PUBLISH_ANSWERS, id),
            {},
            { skipErrorCodes: [400] }
        ),
        response: (id: string, data: object) => this.makeRequest('post', this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.RESPONSE, id), data),
        answers: (id: string) => {
            return new Promise<AxiosResponse<Poll, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Poll>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.ANSWERS, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Poll(resp.data)
                    }))
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        items: {
            getAll: (id: string) => {
                return new Promise<AxiosResponse<PollItem[], AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<PollItem[]>(
                        'get',
                        this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.ITEMS.GET_ALL, id),
                        {},
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: resp.data.map((i: any) => new PollItem(i))
                        }))
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            create: (data: object) => {
                return new Promise<AxiosResponse<PollItem, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest(
                        'post',
                        BACKEND_ROUTES.POLLS.ITEMS.CREATE,
                        data
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: new PollItem(resp.data)
                        }))
                        .catch((err) => reject(err))
                })
            },
            update: (id: string, data: object) => this.makeRequest(
                'put',
                this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.ITEMS.UPDATE, id),
                data
            ),
            delete: (id: string) => this.makeRequest(
                'delete',
                this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.ITEMS.DELETE, id)
            ),
        },
        options: {
            getAll: (id: string) => {
                return new Promise<AxiosResponse<PollOption[], AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<PollOption[]>(
                        'get',
                        this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.OPTIONS.GET_ALL, id),
                        {},
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: resp.data.map((i: any) => new PollOption(i))
                        }))
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            create: (data: object) => {
                return new Promise<AxiosResponse<PollOption, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest(
                        'post',
                        BACKEND_ROUTES.POLLS.OPTIONS.CREATE,
                        data
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: new PollOption(resp.data)
                        }))
                        .catch((err) => reject(err))
                })
            },
            update: (id: string, data: object) => this.makeRequest(
                'put',
                this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.OPTIONS.UPDATE, id),
                data
            ),
            delete: (id: string) => this.makeRequest(
                'delete',
                this.joinUrlWithParamsId(BACKEND_ROUTES.POLLS.OPTIONS.DELETE, id)
            ),
        },
    }

    tickets = {
        getAll: (filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Ticket[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<Ticket[]>>(
                    'get',
                    BACKEND_ROUTES.TICKETS.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new Ticket(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string | number) => {
            return new Promise<AxiosResponse<Ticket, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Ticket>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.TICKETS.GET_ONE, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Ticket(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        create: (data: any, fileData: string[]) => this.makeRequest(
            'post',
            BACKEND_ROUTES.TICKETS.CREATE,
            data,
            { skipAutoFormDataTransform: true, fileData: fileData }
        ),
        update: (id: string, data: any, fileData?: string[]) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.TICKETS.UPDATE, id),
            data,
            fileData !== undefined
                ? { skipAutoFormDataTransform: true, fileData }
                : { }
        ),
        treat: (id: string, data: any) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.TICKETS.TREAT, id),
            data
        ),
        reaffect: (id: string, data: any) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.TICKETS.REAFFECT, id),
            data
        ),
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(BACKEND_ROUTES.TICKETS.DELETE, id)),
        types: {
            getAll: (filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<TicketType[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<TicketType[]>>(
                        'get',
                        BACKEND_ROUTES.TICKETS.TYPES.GET_ALL,
                        filterParams || null,
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new TicketType(d)),
                            }
                        }))
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            getOne: (id: string | number) => {
                return new Promise<AxiosResponse<TicketType, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<TicketType>(
                        'get',
                        this.joinUrlWithParamsId(BACKEND_ROUTES.TICKETS.TYPES.GET_ONE, id),
                        {},
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: new TicketType(resp.data)
                        }))
                        .catch(err => reject(err))
                })
            },
            create: (data: any) => this.makeRequest(
                'post',
                BACKEND_ROUTES.TICKETS.TYPES.CREATE,
                data
            ),
            update: (id: string, data: any) => this.makeRequest(
                'put',
                this.joinUrlWithParamsId(BACKEND_ROUTES.TICKETS.TYPES.UPDATE, id),
                data
            ),
        }
    }

    events = {
        getAll: (isAdmin: boolean, filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Event[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<Event[]>>(
                    'get',
                    isAdmin ? BACKEND_ROUTES.EVENTS.GET_ALL_ADMIN : BACKEND_ROUTES.EVENTS.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new Event(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        getOne: (id: string | number) => {
            return new Promise<AxiosResponse<Event, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Event>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.GET_ONE, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Event(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        activate: (id: string | number) => {
            return new Promise<AxiosResponse<Event, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Event>(
                    'put',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.ACTIVATE, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Event(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        reactivate: (id: string | number) => {
            return new Promise<AxiosResponse<Event, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Event>(
                    'put',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.REACTIVATE, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Event(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        close: (id: string | number) => {
            return new Promise<AxiosResponse<Event, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Event>(
                    'put',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.CLOSE, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Event(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        sendReminder: (id: string | number) => {
            return new Promise<AxiosResponse<Event, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Event>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.REMIND, id),
                    {},
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: new Event(resp.data)
                    }))
                    .catch(err => reject(err))
            })
        },
        presences: (id: string | number, filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<EventPresence[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<EventPresence[]>>(
                    'get',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.PRESENCES, id),
                    filterParams || null,
                )
                .then((resp) => resolve({
                    ...resp,
                    data: {
                        ...resp.data,
                        content: resp.data.content.map(d => new EventPresence(d)),
                    }
                }))
                .catch(err => reject(err))
            })
        },
        changePresences: (id: string | number, data: object, fileData: string[]) => {
            return new Promise<AxiosResponse<Event, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<Event>(
                    'put',
                    this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.PRESENCES, id),
                    data,
                    { skipAutoFormDataTransform: true, fileData: fileData }
                )
                .then((resp) => resolve({
                    ...resp,
                    data: new Event(resp.data)
                }))
                .catch(err => reject(err))
            })
        },
        createOrUpdateDocument: (data: any, fileData: string[]) => this.makeRequest<EventDocument[]>(
            'post',
            BACKEND_ROUTES.EVENTS.CREATE_OR_UPDATE_DOCUMENTS,
            data,
            { skipAutoFormDataTransform: true, fileData }
        ),
        documents: (filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<EventDocument[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<EventDocument[]>>(
                    'get',
                    BACKEND_ROUTES.EVENTS.GET_DOCUMENTS,
                    filterParams || null,
                )
                .then((resp) => resolve({
                    ...resp,
                    data: {
                        ...resp.data,
                        content: resp.data.content.map(d => new EventDocument(d)),
                    }
                }))
                .catch(err => reject(err))
            })
        },
        create: (data: any) => this.makeRequest(
            'post',
            BACKEND_ROUTES.EVENTS.CREATE,
            data,
        ),
        update: (id: string, data: any) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.UPDATE, id),
            data
        ),
        eventStats: () => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<EventPresenceStat[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<EventPresenceStat[]>('get', BACKEND_ROUTES.EVENTS.STAT_EVENT, {})
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            pageable: {
                                pageNumber: 1,
                                pageSize: 100,
                            },
                            totalPages: 1,
                            totalElements: resp.data.length,
                            content: resp.data.map(d => new EventPresenceStat(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        communityStats: () => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<EventPresenceStat[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<EventPresenceStat[]>('get', BACKEND_ROUTES.EVENTS.STAT_COMMUNITY, {})
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            pageable: {
                                pageNumber: 1,
                                pageSize: 100,
                            },
                            totalPages: 1,
                            totalElements: resp.data.length,
                            content: resp.data.map(d => new EventPresenceStat(d)),
                        }
                    }))
                    .catch(err => reject(err))
            })
        },
        paid: (token: string, reference: string) => this.makeRequest('post', BACKEND_ROUTES.PAYMENT.VALIDATE_EVENT_PENALITY, {token, reference}),
        downloadReceipt: (reference: string) => this.makeRequest(
            'get',
            this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.PENALITY_DOWNLOAD_RECEIPT, reference),
            null,
        ),
        createPaymentToken: (data: {reference: string, description?: string|null, is_manual_payment?: boolean, receivedDate?: string}) => this.makeRequest('post', BACKEND_ROUTES.PAYMENT.CREATE_EVENT_PENALITY_TOKEN, data, { removeTrashValueRequest: true, skipErrorCodes: [412] }),
        delete: (id: string) => this.makeRequest('delete', this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.DELETE, id)),
        types: {
            getAll: (filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<EventType[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<EventType[]>('get', BACKEND_ROUTES.EVENTS.TYPES.GET_ALL, filterParams || null)
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                pageable: {
                                    pageNumber: 1,
                                    pageSize: 100,
                                },
                                totalPages: 1,
                                totalElements: resp.data.length,
                                content: resp.data.map(d => new EventType(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            getOne: (id: string | number) => {
                return new Promise<AxiosResponse<EventType, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<EventType>(
                        'get',
                        this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.TYPES.GET_ONE, id),
                        {},
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: new EventType(resp.data)
                        }))
                        .catch(err => reject(err))
                })
            },
            create: (data: any) => this.makeRequest(
                'post',
                BACKEND_ROUTES.EVENTS.TYPES.CREATE,
                data
            ),
            update: (id: string, data: any) => this.makeRequest(
                'put',
                this.joinUrlWithParamsId(BACKEND_ROUTES.EVENTS.TYPES.UPDATE, id),
                data
            ),
        }
    }

    transfers = {
        getAll: (isAdmin: boolean, filterParams: object) => {
            return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Transfer[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                this.makeRequest<RPNClientApiPaginatedResponse<Transfer[]>>(
                    'get',
                    isAdmin ? BACKEND_ROUTES.TRANSFERS.ADMIN.GET_ALL : BACKEND_ROUTES.TRANSFERS.GET_ALL,
                    filterParams || null,
                )
                    .then((resp) => resolve({
                        ...resp,
                        data: {
                            ...resp.data,
                            content: resp.data.content.map(d => new Transfer(d)),
                        }
                    }))
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        create: (data: any) => this.makeRequest('post', BACKEND_ROUTES.TRANSFERS.CREATE, data),
        createAdmin: (data: any) => this.makeRequest('post', BACKEND_ROUTES.TRANSFERS.CREATE_ADMIN, data),
        response: (id: string, isAdmin: boolean, response: boolean, reason: string|null = null) => this.makeRequest(
            'put',
            this.joinUrlWithParamsId(isAdmin ? BACKEND_ROUTES.TRANSFERS.ADMIN.RESPONSE : BACKEND_ROUTES.TRANSFERS.RESPONSE, id),
            { response, reason }
        ),
        paid: (token: string, references: string) => this.makeRequest('post', BACKEND_ROUTES.PAYMENT.VALIDATE_TRANSFER, {token, references}),
        createPaymentToken: (datas: {reference?: string|null, description?: string|null}) => this.makeRequest('post', BACKEND_ROUTES.PAYMENT.CREATE_TRANSFER_TOKEN, datas, { removeTrashValueRequest: true, skipErrorCodes: [412] }),
        cancel: (id: string) => this.makeRequest('put', this.joinUrlWithParamsId(BACKEND_ROUTES.TRANSFERS.CANCEL, id), {}),
    }

    security = {
        permissions: {
            getAll: (filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Permission[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<Permission[]>('get', BACKEND_ROUTES.SECURITY.PERMISSIONS.GET_ALL, filterParams || null)
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                pageable: {
                                    pageNumber: 1,
                                    pageSize: 10,
                                },
                                totalPages: 1,
                                totalElements: resp.data.length,
                                content: resp.data.map(d => new Permission(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
        },
        roles: {
            getAll: (filterParams: object) => {
                return new Promise<AxiosResponse<RPNClientApiPaginatedResponse<Role[]>, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<RPNClientApiPaginatedResponse<Role[]>>(
                        'get',
                        BACKEND_ROUTES.SECURITY.ROLES.GET_ALL,
                        filterParams || null
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: {
                                ...resp.data,
                                content: resp.data.content.map(d => new Role(d)),
                            }
                        }))
                        .catch(err => reject(err))
                })
            },
            getOne: (reference: string) => {
                return new Promise<AxiosResponse<Role, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<Role>(
                        'get',
                        this.joinUrlWithParamsId(BACKEND_ROUTES.SECURITY.ROLES.GET_ONE, reference),
                        null
                    )
                        .then((resp) => resolve({
                            ...resp,
                            data: new Role(resp.data)
                        }))
                        .catch(err => reject(err))
                })
            },
            create: (data: any) => this.makeRequest('post', BACKEND_ROUTES.SECURITY.ROLES.CREATE, data, { removeTrashValueRequest: true }),
            update: (id: string, data: any) => this.makeRequest(
                'put',
                this.joinUrlWithParamsId(BACKEND_ROUTES.SECURITY.ROLES.UPDATE, id),
                data,
                // { noFormData: true },
            ),
            delete: (id: string, force: boolean = false) => this.makeRequest(
                'delete',
                this.joinUrlWithParamsId(BACKEND_ROUTES.SECURITY.ROLES.DELETE, id),
                { force },
                { skipErrorCodes: [409] }
            ),
        },

        systemSettings: {
            getAll: () => {
                return new Promise<AxiosResponse<SystemSettings, AxiosRequestConfigExtended>>((resolve, reject) => {
                    this.makeRequest<SystemSettings>('get', BACKEND_ROUTES.SECURITY.SYSTEM_SETTINGS.GET_ALL)
                        .then((resp) => resolve({
                            ...resp,
                            data: new SystemSettings(resp.data as unknown as Array<any>)
                        }))
                        .catch(err => reject(err))
                })
            },
            update: (data: any) => this.makeRequest(
                'put',
                BACKEND_ROUTES.SECURITY.SYSTEM_SETTINGS.UPDATE,
                data,
                { skipAutoFormDataTransform: false, skipSnakeCaseTransform: true }
            ),
        },
    }

    payment = {
        createSubscriptionToken: (reference: string) => this.makeRequest('post', this.joinUrlWithParams(BACKEND_ROUTES.PAYMENT.CREATE_SUBSCRIPTION_TOKEN, [{param: 'reference', value: reference}]), {}, { removeTrashValueRequest: true }),
        validateSubscription: (reference: string, token: string) => this.makeRequest('post', this.joinUrlWithParams(BACKEND_ROUTES.PAYMENT.VALIDATE_SUBSCRIPTION, [{param: 'reference', value: reference}]), {token}, { removeTrashValueRequest: true }),
    }

    staticTypes = {
        idCardTypes: [
            { label: globalT('form.field.id.card.type.ID_CARD'), value: 'ID_CARD' },
            { label: globalT('form.field.id.card.type.NAS'), value: 'NAS' },
            { label: globalT('form.field.id.card.type.PASSPORT'), value: 'PASSPORT' },
            { label: globalT('form.field.id.card.type.DRIVER_LICENCE'), value: 'DRIVER_LICENCE' },
            { label: globalT('form.field.id.card.type.BIRTH_CERTIFICATE'), value: 'BIRTH_CERTIFICATE' },
            { label: globalT('form.field.id.card.type.BIRTH_DECLARATION'), value: 'BIRTH_DECLARATION' },
            { label: globalT('form.field.id.card.type.HEALTH_INSURANCE_NUMBER'), value: 'HEALTH_INSURANCE_NUMBER' },
            { label: globalT('form.field.id.card.type.RESIDENT_ID_CARD'), value: 'RESIDENT_ID_CARD' },
            { label: globalT('form.field.id.card.type.REGION_ID_CARD'), value: 'REGION_ID_CARD' },
            { label: globalT('form.field.id.card.type.REGION_HEALTH_CARD'), value: 'REGION_HEALTH_CARD' },
            { label: globalT('form.field.id.card.type.WORK_PERMIT'), value: 'WORK_PERMIT' },
            { label: globalT('form.field.id.card.type.DOCUMENT_PROTECTED_PEOPLE'), value: 'DOCUMENT_PROTECTED_PEOPLE' },
        ],
        memberTypes: MemberTypeEnum
            .enumValues
            // @ts-ignore
            .map(item => formatMemberTypeToForm(item)),
        memberStatuses: MemberStatusEnum
            .enumValues
            // @ts-ignore
            .map(item => formatMemberStatusToForm(item)),
        communityStatuses: CommunityStatusEnum
            .enumValues
            // @ts-ignore
            .map(item => formatCommunityStatusToForm(item)),
        transferTypes: TransferTypeEnum
            .enumValues
            // @ts-ignore
            .map(item => formatTransferTypeToForm(item)),
        transferStatuses: TransferStatusEnum
            .enumValues
            // @ts-ignore
            .filter(item => ![TransferStatusEnum.activated, TransferStatusEnum.suspended, TransferStatusEnum.blocked, TransferStatusEnum.confirmed].includes(item))
            // @ts-ignore
            .map(item => formatTransferStatusToForm(item)),
        relationTypes: RelationTypeEnum
            .enumValues
            // @ts-ignore
            .map(item => formatRelationTypeToForm(item)),
        contributionStatusTypes: ContributionStatusEnum
            .enumValues
            // @ts-ignore
            .map(item => formatContributionStatusToForm(item)),
        deathRequestStatusTypes: DeathMemberStatusEnum
            .enumValues
            // @ts-ignore
            .map(item => formatContributionStatusToForm(item)),
        canadaRegions: [
            { label: 'Alberta', value: 'AB' },
            { label: 'British Columbia', value: 'BC' },
            { label: 'Manitoba', value: 'MB' },
            { label: 'New Brunswick', value: 'NB' },
            { label: 'Newfoundland and Labrador', value: 'NL' },
            { label: 'North West Territories', value: 'NT' },
            { label: 'Nova Scotia', value: 'NS' },
            { label: 'Nunavut', value: 'NU' },
            { label: 'Ontario', value: 'ON' },
            { label: 'Prince Edward Island', value: 'PE' },
            { label: 'Quebec', value: 'QC' },
            { label: 'Saskatchewan', value: 'SK' },
            { label: 'Yukon', value: 'YT' },
        ]
    }

}
