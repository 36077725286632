import {Enumify} from "./Enumify";
import {formatEventStatusToForm} from "../helpers/businessHelper";

export default class EventStatusEnum extends Enumify<string> {
    static readonly pending = new EventStatusEnum('PENDING');
    static readonly active = new EventStatusEnum('ACTIVE');
    static readonly reactive = new EventStatusEnum('REACTIVE');
    static readonly close = new EventStatusEnum('CLOSE');
    static readonly absent = new EventStatusEnum('ABSENT');
    static readonly present = new EventStatusEnum('PRESENT');
    static readonly late = new EventStatusEnum('LATE');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatEventStatusToForm(this)
    }

    get statusMap() {
        switch (this) {
            case EventStatusEnum.pending:
                return { color: 'warning' }
            case EventStatusEnum.active:
            case EventStatusEnum.reactive:
                return { color: 'primary' }
            case EventStatusEnum.close:
                return { color: 'danger' }
            case EventStatusEnum.present:
            case EventStatusEnum.late:
            case EventStatusEnum.absent:
                return { color: 'dark' }
            default:
                return { color: 'warning' }
        }
    }
}
