import { LanguageDict } from "../types"

export default class EventPresenceStat {
    public nameFr: string
    public nameEn: string
    public place: string
    public total: number
    public presence: number
    public late: number

    constructor(data: any) {
        this.nameFr = data.nameFr
        this.nameEn = data.nameEn
        this.place = data.place
        this.total = data.total
        this.presence = data.presence
        this.late = data.late
    }

    getName = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`name${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
