import dayjs, {Dayjs} from "dayjs";
import {LanguageDict} from "../types";

export default class EventType {
    public id: string
    public nameFr: string
    public nameEn: string
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public descriptionFr: string
    public descriptionEn: string

    constructor(data: any) {
        this.id = data.reference
        this.nameFr = data.nameFr
        this.nameEn = data.nameEn
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.descriptionFr = data.descriptionFr
        this.descriptionEn = data.descriptionEn
    }

    getName = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`name${locale[0].toUpperCase()}${locale.substring(1)}`]
    }

    getDescription = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`description${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
