/**
 * This file list all permissions handled by the app
 */

export default class Permissions {
	static security = {
		roles: 'role-management',
		systemSettings: 'setting-management',
	}

	static communities = {
		self: 'communities-management',
		members: 'community-members-management',
		update: 'community-update',
		pendingCommunities: 'pending-communities-management',
	}

	static members = 'members-management'

	static adverts = {
		member: 'adverts-management',
	}

	static partners =  'partners-management';

	static articles = {
		member: 'articles-management',
	}

	static deaths = {
		member: 'deaths-management',
		admin: 'admin-deaths-management',
	}

	static events = {
		member: 'events-management',
		admin: 'admin-events-management',
	}

	static documents = {
		member: 'documents-management',
		admin: 'admin-documents-management',
	}

	static finances = {
		member: 'finances-management',
		admin: 'admin-finances-management',
	}

	static founders = {
		member: 'founders-management',
	}

	static polls = {
		member: 'polls-management',
		admin: 'admin-polls-management',
	}

	static tickets = {
		member: 'tickets-management',
		types: 'tickets-types-management',
	}

	static transfers = {
		member: 'transferts-management',
		admin: 'admin-transferts-management',
	}

	static managers = 'managers-management'
}
