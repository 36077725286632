import dayjs, {Dayjs} from "dayjs";
import MemberTypeEnum from "../enums/MemberTypeEnum";

export default class MemberDeleted {

    public matricule: string;
    public inscriptionDate: Dayjs;
    public activationDate: Dayjs;
    public deletionDate: Dayjs;
    public reason: string;
    public userName: string;
    public community?: string
    public memberType: MemberTypeEnum;

    constructor(data: any) {
        this.matricule = data.matricule;
        this.userName = data.userName;
        this.reason = data.reason;
        this.community = data.community || "-";
        this.memberType = MemberTypeEnum.tryParse(data.memberType);
        this.inscriptionDate = dayjs(data.inscriptionDate);
        this.activationDate = dayjs(data.activationDate);
        this.deletionDate = dayjs(data.deletionDate);
    }

}
